import React from 'react';
import { Layout, Link } from '../components';
import './sitemap.scss';

export const Sitemap = ({ location, pathContext }) => {
  const { pages = [], locations = [] } = pathContext;

  const seo = {
    title: 'Sitemp | YORDAR',
  };
  return (
    <Layout location={location} seo={seo}>
      <div className="wrapper sitemap">
        <h1>Sitemap</h1>
        <div className="sitemap-section">
          <h4>Pages</h4>
          <ul className="sitemap-list">
            {pages
              .sort((a, b) => (a.data.page_title.text > b.data.page_title.text ? 1 : -1))
              .map(page => {
                const { uid, data } = page;
                const { page_title: pageTitle } = data;
                return (
                  <li>
                    <Link to={`/${uid}`}>{pageTitle.text}</Link>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="sitemap-section">
          <h4>Location</h4>
          <ul className="sitemap-list">
            {locations.map(page => {
              const { uid, data } = page;
              const {
                suburb,
                catering_active: cateringActive,
                office_snacks_active: snacksActive,
                office_milk_active: milkActive,
                office_fruit_active: fruitActive,
              } = data;
              return (
                <>
                  {cateringActive === 'Yes' && (
                    <li>
                      <Link to={`/office-catering/${uid}`}>Office Catering {suburb.text}</Link>
                    </li>
                  )}
                  {snacksActive === 'Yes' && (
                    <li>
                      <Link to={`/office-snacks-pantry/${uid}`}>Office Snacks {suburb.text}</Link>
                    </li>
                  )}
                  {milkActive === 'Yes' && (
                    <li>
                      <Link to={`/office-milk/${uid}`}>Office Milk {suburb.text}</Link>
                    </li>
                  )}
                  {fruitActive === 'Yes' && (
                    <li>
                      <Link to={`/office-fruit/${uid}`}>Office Fruit {suburb.text}</Link>
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Sitemap;
